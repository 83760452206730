<template>
  <div>
    <project-kanban-add-new
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      :task-status-options="taskStatusOptions"
      :task-type-options="taskTypeOptions"
      :task-priority-options="taskPriorityOptions"
    />
    <div>
      <b-button
        @click="isAddNewTaskSidebarActive = true"
        v-if="restrictions('button_new_task')"
        variant="outline-primary mr-1"
      >
        <feather-icon icon="PlusIcon" size="12" />
        <span class="ml-25 align-middle">{{
          $t("message.tasks.createNewTask")
        }}</span>
      </b-button>

      <b-row class="mt-2">
        <b-col md="9">
          <b-input-group class="mr-1">
            <b-form-input
              v-model="searchQuery"
              @keyup.enter="searchWord()"
              :placeholder="$t('message.bookmark.search')"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="searchWord()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <br />
        <br />
        <br />
        <b-col md="3">
          <v-select
            v-if="sprintList.length === 0"
            :placeholder="$t('selectSprint')"
            disabled
          />
          <v-select
            v-else
            v-model="sprintSelected"
            :options="sprintOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="task-status"
            @input="filterBySprintId(sprintSelected)"
            :placeholder="$t('selectSprint')"
          />
        </b-col>
      </b-row>

      <div class="text-center">
        <h4>{{ projectName }}</h4>
      </div>
      <template v-if="loading === true">
        <div class="text-center my-17">
          <b-spinner class="align-middle"></b-spinner>
          <strong> {{ $t("message.load") }}...</strong>
        </div>
      </template>

      <b-row v-else>
        <!-- people group BACKLOG -->
        <b-col md="3">
          <b-col md="13">
            <h6
              class="text-primary font-weight-bold mb-2 text-center stick"
              style="margin-top: 20px"
            >
              {{ $t("message.tasks.pending") }} ({{ backlog.length }})
            </h6>
            <ul class="list-group list-group-flush">
              <!-- draggable -->
              <draggable
                :list="backlog"
                :animation="200"
                @change="seeChange($event, 'Backlog')"
                :style="minHeight()"
                drag-class="drag-card"
                ghost-class="ghost-card"
                tag="ul"
                group="people"
                class="list-group list-group-flush"
              >
                <b-list-group
                  v-for="(listItem, index) in backlog"
                  :key="index"
                  tag="li"
                  class="mb-1"
                >
                  <b-card class="mb-0">
                    <h6 class="item-name">
                      <b-link
                        class="text-body h4"
                        v-b-modal.modal-view
                        @click="setTaskData(listItem)"
                      >
                        {{ listItem.subject }}
                      </b-link>
                      <b-link
                        class="text-body h4"
                        v-if="restrictions('button_edit_task')"
                        :to="{
                          name: 'apps-task-edit',
                          params: { id: listItem.id },
                        }"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="cursor-pointer float-right"
                        />
                      </b-link>
                    </h6>
                    <b-card-text class="item-description">
                      <span
                        v-html="listItem.description.substring(0, 50) + '..'"
                      ></span>
                    </b-card-text>
                    <b-badge :variant="badgeColor(listItem.priority)">
                      {{ taskPriorityLocale(listItem.priority) }}
                    </b-badge>
                    <b-badge
                      class="ml-1"
                      v-if="listItem.type === 'Projeto'"
                      variant="warning"
                    >
                      <feather-icon
                        :id="'backlog-project-' + listItem.id"
                        icon="FileTextIcon"
                      />
                      <b-tooltip
                        :target="'backlog-project-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("proj") }}
                      </b-tooltip>
                    </b-badge>
                    <b-badge class="ml-1" v-else variant="info">
                      <feather-icon
                        :id="'backlog-projAp-' + listItem.id"
                        icon="FilePlusIcon"
                      />
                      <b-tooltip
                        :target="'backlog-projAp-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("projAp") }}
                      </b-tooltip>
                    </b-badge>
                    <br />

                    <!-- avatar group -->

                    <b-avatar-group class="mt-2 pt-50" size="33">
                      <b-avatar
                        variant="danger"
                        v-b-tooltip.hover
                        title="Não atribuído"
                        class="pull-up"
                      >
                        <span class="d-flex align-items-center">
                          <feather-icon icon="UserXIcon" size="16" />
                        </span>
                      </b-avatar>
                    </b-avatar-group>

                    <br />
                    <div class="item-wrapper">
                      <div class="item-rating">
                        <ul class="unstyled-list list-inline" />
                      </div>
                      <div>
                        <h6 class="item-price" style="font-weight: normal">
                          {{ $t("message.date.start") }}
                          <b>{{ localeDate(listItem.startDate) }}</b>
                          <br v-if="isActive" />
                          {{ $t("message.date.end") }}
                          <b v-if="lateTask(listItem)" style="color: red">{{
                            localeDate(listItem.endDate)
                          }}</b>
                          <b v-else>{{ localeDate(listItem.endDate) }}</b>
                        </h6>
                      </div>
                    </div>
                    <b-progress
                      variant="primary"
                      class="progress-bar-secondary mt-2 ml-0"
                    >
                      <b-progress-bar
                        :value="listItem.percentage"
                        :label="`${((listItem.percentage / 100) * 100).toFixed(
                          0
                        )}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-card>
                </b-list-group>
              </draggable>
            </ul>
          </b-col>
        </b-col>

        <!-- people group PENDING -->
        <b-col md="3">
          <b-col md="13">
            <h6
              class="text-primary font-weight-bold mb-2 text-center stick"
              style="margin-top: 20px"
            >
              {{ $t("message.projects.running") }} ({{ in_progress.length }})
            </h6>
            <ul class="list-group list-group-flush">
              <!-- draggable -->
              <draggable
                :list="in_progress"
                :animation="200"
                @change="seeChange($event, 'Em execução')"
                :style="minHeight()"
                ghost-class="ghost-card"
                drag-class="drag-card"
                tag="ul"
                group="people"
                class="list-group list-group-flush"
              >
                <b-list-group
                  v-for="(listItem, index) in in_progress"
                  :key="index"
                  tag="li"
                  class="mb-1"
                >
                  <b-card class="mb-0">
                    <h6 class="item-name">
                      <b-link
                        class="text-body h4"
                        v-b-modal.modal-view
                        @click="setTaskData(listItem)"
                      >
                        {{ listItem.subject }}
                      </b-link>
                      <b-link
                        class="text-body h4"
                        v-if="restrictions('button_edit_task')"
                        :to="{
                          name: 'apps-task-edit',
                          params: { id: listItem.id },
                        }"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="cursor-pointer float-right"
                        />
                      </b-link>
                    </h6>
                    <b-card-text class="item-description">
                      <span
                        v-html="listItem.description.substring(0, 50) + '..'"
                      ></span>
                    </b-card-text>
                    <b-badge :variant="badgeColor(listItem.priority)">
                      {{ taskPriorityLocale(listItem.priority) }}
                    </b-badge>
                    <b-badge
                      class="ml-1"
                      v-if="listItem.type === 'Projeto'"
                      variant="warning"
                    >
                      <feather-icon
                        :id="'progress-project-' + listItem.id"
                        icon="FileTextIcon"
                      />
                      <b-tooltip
                        :target="'progress-project-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("proj") }}
                      </b-tooltip>
                    </b-badge>
                    <b-badge class="ml-1" v-else variant="info">
                      <feather-icon
                        :id="'progress-projAp-' + listItem.id"
                        icon="FilePlusIcon"
                      />
                      <b-tooltip
                        :target="'progress-projAp-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("projAp") }}
                      </b-tooltip>
                    </b-badge>
                    <br />

                    <!-- avatar group -->
                    <b-avatar-group class="mt-2 pt-50" size="33">
                      <b-avatar
                        v-for="avatar in avatarFullName(listItem)"
                        :key="avatar.avatar"
                        v-b-tooltip.hover.bottom="avatar.fullName"
                        :text="avatar.avatar"
                        v-bind:src="
                          'data:image/jpeg;base64,' + avatar.avatarImg
                        "
                        class="pull-up"
                      />
                    </b-avatar-group>

                    <br />
                    <div class="item-wrapper">
                      <div class="item-rating">
                        <ul class="unstyled-list list-inline" />
                      </div>
                      <div>
                        <h6 class="item-price" style="font-weight: normal">
                          {{ $t("message.date.start") }}
                          <b>{{ localeDate(listItem.startDate) }}</b>
                          <br v-if="isActive" />
                          {{ $t("message.date.end") }}
                          <b v-if="lateTask(listItem)" style="color: red">{{
                            localeDate(listItem.endDate)
                          }}</b>
                          <b v-else>{{ localeDate(listItem.endDate) }}</b>
                        </h6>
                      </div>
                    </div>
                    <b-progress
                      variant="primary"
                      class="progress-bar-secondary mt-2 ml-0"
                    >
                      <b-progress-bar
                        :value="listItem.percentage"
                        :label="`${((listItem.percentage / 100) * 100).toFixed(
                          0
                        )}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-card>
                </b-list-group>
              </draggable>
            </ul>
          </b-col>
        </b-col>

        <!-- people group REJECTED -->
        <b-col md="3">
          <b-col md="13">
            <h6
              class="text-primary font-weight-bold mb-2 text-center stick"
              style="margin-top: 20px"
            >
              {{ $t("message.tasks.rejected") }} ({{ rejected.length }})
            </h6>
            <ul class="list-group list-group-flush">
              <!-- draggable -->
              <draggable
                :list="rejected"
                :animation="200"
                @change="seeChange($event, 'Rejeitado')"
                :style="minHeight()"
                ghost-class="ghost-card"
                drag-class="drag-card"
                tag="ul"
                group="people"
                class="list-group list-group-flush"
              >
                <b-list-group
                  v-for="(listItem, index) in rejected"
                  :key="index"
                  tag="li"
                  class="mb-1"
                >
                  <b-card class="mb-0">
                    <h6 class="item-name">
                      <b-link
                        class="text-body h4"
                        v-b-modal.modal-view
                        @click="setTaskData(listItem)"
                      >
                        {{ listItem.subject }}
                      </b-link>
                      <b-link
                        class="text-body h4"
                        v-if="restrictions('button_edit_task')"
                        :to="{
                          name: 'apps-task-edit',
                          params: { id: listItem.id },
                        }"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="cursor-pointer float-right"
                        />
                      </b-link>
                    </h6>
                    <b-card-text class="item-description">
                      <span
                        v-html="listItem.description.substring(0, 50) + '..'"
                      ></span>
                    </b-card-text>
                    <b-badge :variant="badgeColor(listItem.priority)">
                      {{ taskPriorityLocale(listItem.priority) }}
                    </b-badge>
                    <b-badge
                      class="ml-1"
                      v-if="listItem.type === 'Projeto'"
                      variant="warning"
                    >
                      <feather-icon
                        :id="'rejected-project-' + listItem.id"
                        icon="FileTextIcon"
                      />
                      <b-tooltip
                        :target="'rejected-project-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("proj") }}
                      </b-tooltip>
                    </b-badge>
                    <b-badge class="ml-1" v-else variant="info">
                      <feather-icon
                        :id="'rejected-projAp-' + listItem.id"
                        icon="FilePlusIcon"
                      />
                      <b-tooltip
                        :target="'rejected-projAp-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("projAp") }}
                      </b-tooltip>
                    </b-badge>
                    <br />

                    <!-- avatar group -->
                    <b-avatar-group class="mt-2 pt-50" size="33">
                      <b-avatar
                        v-for="avatar in avatarFullName(listItem)"
                        :key="avatar.avatar"
                        v-b-tooltip.hover.bottom="avatar.fullName"
                        :text="avatar.avatar"
                        v-bind:src="
                          'data:image/jpeg;base64,' + avatar.avatarImg
                        "
                        class="pull-up"
                      />
                    </b-avatar-group>

                    <br />
                    <div class="item-wrapper">
                      <div class="item-rating">
                        <ul class="unstyled-list list-inline" />
                      </div>
                      <div>
                        <h6 class="item-price" style="font-weight: normal">
                          {{ $t("message.date.start") }}
                          <b>{{ localeDate(listItem.startDate) }}</b>
                          <br v-if="isActive" />
                          {{ $t("message.date.end") }}
                          <b v-if="lateTask(listItem)" style="color: red">{{
                            localeDate(listItem.endDate)
                          }}</b>
                          <b v-else>{{ localeDate(listItem.endDate) }}</b>
                        </h6>
                      </div>
                    </div>
                    <b-progress
                      variant="primary"
                      class="progress-bar-secondary mt-2 ml-0"
                    >
                      <b-progress-bar
                        :value="listItem.percentage"
                        :label="`${((listItem.percentage / 100) * 100).toFixed(
                          0
                        )}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-card>
                </b-list-group>
              </draggable>
            </ul>
          </b-col>
        </b-col>

        <!-- people group DONE -->
        <b-col md="3">
          <b-col md="13">
            <h6
              class="text-primary font-weight-bold mb-2 text-center stick"
              style="margin-top: 20px"
            >
              {{ $t("message.tasks.finished") }} ({{ done.length }})
            </h6>
            <ul class="list-group list-group-flush">
              <!-- draggable -->
              <draggable
                :list="done"
                :animation="200"
                @change="seeChange($event, 'Finalizado')"
                :style="minHeight()"
                ghost-class="ghost-card"
                drag-class="drag-card"
                tag="ul"
                group="people"
                class="list-group list-group-flush"
              >
                <b-list-group
                  v-for="(listItem, index) in done"
                  :key="index"
                  tag="li"
                  class="mb-1"
                >
                  <b-card class="mb-0">
                    <h6 class="item-name">
                      <b-link
                        class="text-body h4"
                        v-b-modal.modal-view
                        @click="setTaskData(listItem)"
                      >
                        {{ listItem.subject }}
                      </b-link>
                      <b-link
                        class="text-body h4"
                        v-if="restrictions('button_edit_task')"
                        :to="{
                          name: 'apps-task-edit',
                          params: { id: listItem.id },
                        }"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="cursor-pointer float-right"
                        />
                      </b-link>
                    </h6>
                    <b-card-text class="item-description">
                      <span
                        v-html="listItem.description.substring(0, 50) + '..'"
                      ></span>
                    </b-card-text>
                    <b-badge :variant="badgeColor(listItem.priority)">
                      {{ taskPriorityLocale(listItem.priority) }}
                    </b-badge>
                    <b-badge
                      class="ml-1"
                      v-if="listItem.type === 'Projeto'"
                      variant="warning"
                    >
                      <feather-icon
                        :id="'done-project-' + listItem.id"
                        icon="FileTextIcon"
                      />
                      <b-tooltip
                        :target="'done-project-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("proj") }}
                      </b-tooltip>
                    </b-badge>
                    <b-badge class="ml-1" v-else variant="info">
                      <feather-icon
                        :id="'done-projAp-' + listItem.id"
                        icon="FilePlusIcon"
                      />
                      <b-tooltip
                        :target="'done-projAp-' + listItem.id"
                        triggers="hover"
                        no-fade
                      >
                        {{ $t("projAp") }}
                      </b-tooltip>
                    </b-badge>
                    <br />

                    <!-- avatar group -->
                    <b-avatar-group class="mt-2 pt-50" size="33">
                      <b-avatar
                        v-for="avatar in avatarFullName(listItem)"
                        :key="avatar.avatar"
                        v-b-tooltip.hover.bottom="avatar.fullName"
                        :text="avatar.avatar"
                        v-bind:src="
                          'data:image/jpeg;base64,' + avatar.avatarImg
                        "
                        class="pull-up"
                      />
                    </b-avatar-group>

                    <br />
                    <div class="item-wrapper">
                      <div class="item-rating">
                        <ul class="unstyled-list list-inline" />
                      </div>
                      <div>
                        <h6 class="item-price" style="font-weight: normal">
                          {{ $t("message.date.start") }}
                          <b>{{ localeDate(listItem.startDate) }}</b>
                          <br v-if="isActive" />
                          {{ $t("message.date.end") }}
                          <b>{{ localeDate(listItem.endDate) }}</b>
                        </h6>
                      </div>
                    </div>
                    <b-progress
                      variant="primary"
                      class="progress-bar-secondary mt-2 ml-0"
                    >
                      <b-progress-bar
                        :value="listItem.percentage"
                        :label="`${((listItem.percentage / 100) * 100).toFixed(
                          0
                        )}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </b-card>
                </b-list-group>
              </draggable>
            </ul>
          </b-col>
        </b-col>
      </b-row>
    </div>

    <!--Task view-->

    <b-modal
      id="modal-view"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Informações da Tarefa"
      hide-footer
    >
      <b-col>
        <b-row>
          <!-- Right: Product Details -->
          <b-col>
            <!-- Product Name -->
            <h2>{{ taskData.subject }}</h2>
            <div class="mt-1">
              <b-badge :variant="badgeColor(taskData.status)">
                {{ taskData.status }}
              </b-badge>
            </div>

            <!-- Avability -->
            <div class="mt-2">
              <b>{{ $t("message.projects.description") }}</b>
            </div>

            <!-- Product Description -->
            <b-card-text style="white-space: pre-line"
              ><span v-html="taskData.description"></span></b-card-text
            ><br />

            <b-row>
              <b-col cols="12" md="3" style="font-weight: bold">
                {{ $t("message.projects.startDate") }}
                <h5 style="font-weight: normal">
                  {{ localeDate(taskData.startDate) }}
                </h5>
              </b-col>
              <b-col cols="12" md="3" style="font-weight: bold">
                {{ $t("message.projects.endDate") }}
                <h5 style="font-weight: normal">
                  {{ localeDate(taskData.endDate) }}
                </h5>
              </b-col>
              <b-col cols="12" md="3" style="font-weight: bold">
                {{ $t("message.tasks.priority") }}
                <h5 style="font-weight: normal">{{ taskData.priority }}</h5>
              </b-col>
              <b-col cols="12" md="3" style="font-weight: bold">
                {{ $t("message.projects.project") }}
                <h5 style="font-weight: normal">{{ taskData.projectName }}</h5>
              </b-col> </b-row
            ><br />

            <b-row v-if="restrictions('show_task_cost')"
              ><b-col cols="12" md="9" style="font-weight: bold">
                {{ $t("message.timeSheet.hoursReleased") }}
                <h5 style="font-weight: normal">
                  {{ taskData.hours_performed }}
                </h5>
              </b-col>
              <b-col cols="12" md="2" style="font-weight: bold">
                {{ $t("message.contract.totalValue") }}
                <h5 style="font-weight: normal">
                  {{ $n(taskData.cost || 0, "currency") }}
                </h5>
              </b-col></b-row
            ><br v-if="restrictions('show_task_cost')"/>            

            <b-row>
              <b-col
                cols="12"
                md="9"
                style="font-weight: bold"
                v-if="taskData.membersEmails != null"
              >
                {{ $t("message.projects.members") }} ({{
                  taskData.membersEmails.length
                }})
                <h5
                  style="font-weight: normal"
                  v-if="taskData.membersEmails.length === 0"
                >
                  Nenhum membro associado
                </h5>
                <h5 style="font-weight: normal" v-else>
                  {{ taskData.membersEmails.join(", ") }}
                </h5>
              </b-col>
              <b-col cols="12" md="3" style="font-weight: bold">
                {{ $t("message.projects.estimatedTime") }}
                <h5 style="font-weight: normal">
                  {{ taskData.estimateTime }} horas
                </h5>
              </b-col> </b-row
            ><br />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label>
                <b> {{ $t("Progress") }}:</b> {{ taskData.percentage }}%
              </template>
              <input
                class="slider"
                v-model="taskData.percentage"
                type="range"
                min="0"
                max="100"
                value="taskData.percentage"
                id="done"
                step="1"
                v-bind:style="gradientColorProgressBar()"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" v-if="filesData.length > 0" />

        <br />
        <b-row>
          <b-col
            style="max-width: 200px; !important"
            v-for="item in filesData"
            :key="item.id"
          >
            <b-card
              text-variant="white"
              class="text-center"
              bg-variant="primary"
            >
              <feather-icon size="40" icon="FileIcon" />

              <div class="truncate">
                <br />
                <span>{{ item.name }}</span>
              </div>
              <br />
              <b-row>
                <b-col md="12"
                  ><b-button
                    size="sm"
                    @click="downloadFile(item.id, item.name)"
                    v-if="restrictions('task_file_download')"
                    ><feather-icon size="20" icon="DownloadCloudIcon"
                  /></b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-timesheet
          class="mt-2 mr-2"
          variant="warning"
          @click="
            timeSheetData.projectId = taskData.projectId;
            timeSheetData.taskId = taskData.id;
          "
        >
          {{ $t("message.timeSheet.createNewRelease") }}
        </b-button>
        <b-button class="mt-2" variant="primary" @click="updateTask()">
          {{ $t("message.buttons.save") }}
        </b-button>
      </div>
      <br />
    </b-modal>

    <b-modal
      id="modal-timesheet"
      modal-class="modal-warning"
      centered
      :title="$t('message.timeSheet.createNewRelease')"
      ok-only
      @ok="submitNewTimeSheet()"
      :ok-title="$t('message.buttons.save')"
    >
      <b-col>
        <!-- Date -->
        <b-form-group label-for="date">
          <template v-slot:label>
            {{ $t("message.tableHeader.date") }}
          </template>
          <flat-pickr
            v-model="timeSheetData.date"
            class="form-control"
            :config="flatConfig"
          />
        </b-form-group>

        <b-row>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label>
                {{ $t("message.date.hours") }}: {{ timeSheetDataHoursQuantity }}
              </template>
              <input
                class="slider-timeSheet"
                v-model="timeSheetDataHoursQuantity"
                type="range"
                min="1"
                max="12"
                value="timeSheetDataHoursQuantity"
                step="1"
                v-bind:style="gradientColorProgressBarTimeSheet()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroupItem,
  BSpinner,
  VBTooltip,
  BAvatarGroup,
  BBadge,
  BLink,
  BFormSpinbutton,
  BProgress,
  BAvatar,
  BListGroup,
  BRow,
  BCol,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BModal,
  BProgressBar,
  BTooltip,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProjectKanbanAddNew from "./ProjectKanbanAddNew.vue";
import AccessControl from "@core/utils/access-control";
import { avatarText } from "@core/utils/filter";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import download from "downloadjs";
import date from "@/@core/utils/date";

export default {
  components: {
    ProjectKanbanAddNew,

    BAvatar,
    BAvatarGroup,
    BProgress,
    BFormSpinbutton,
    BListGroupItem,
    BListGroup,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BModal,
    vSelect,
    flatPickr,
    quillEditor,
    BProgressBar,
    BBadge,
    BLink,
    BSpinner,
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,

    Prism,
    draggable,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      isAddNewTaskSidebarActive: false,

      backlog: [],
      in_progress: [],
      rejected: [],
      done: [],
      taskList: [],
      taskData: [],

      loading: false,

      //Select
      taskStatusOptions: [],
      taskTypeOptions: [],
      taskPriorityOptions: [],
      projectOptions: [],

      flatConfig: {
        dateFormat: "d/m/Y",
      },

      timeSheetDataHoursQuantity: 1,

      userData: store.state.user.userData,
      currentEmployeerObj: store.state.user.employeeData,

      //Project id
      projectId: router.currentRoute.params.id,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
      limitCharactersValue: 20,

      projectName: "",

      projectSelected: "",
      sprintSelected: "",
      sprintOptions: [],
      sprintList: [],

      searchQuery: "",

      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      timeSheetData: {},
    };
  },

  async created() {
    this.getTaskList();
    this.onResize();

    var optionsList = [];
    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.taskStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
          if (response.data[i].id === this.projectId) {
            this.projectName = response.data[i].name;
          }
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}task_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.taskTypeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}task_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.taskPriorityOptions.push({
            label: priorityList[i],
            value: priorityList[i],
          });
        }
      });
  },

  mounted() {
    this.$root.$on("newKanbanTask", (newListTask) => {
      this.searchQuery = "";
      this.sprintSelected = "";
      this.loading = true;

      axios
        .get(`${URL_API}task/project-kanban/${this.projectId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.taskList = [];
          this.taskList = response.data;
          this.createData();
          this.loading = false;
        });
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    updateTask() {
      this.$root.$emit("bv::hide::modal", "modal-view");
      axios({
        method: "put",
        url: `${URL_API}task/percentage/${this.taskData.id}/${this.taskData.percentage}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    async filterBySprintId(sprintId) {
      var listOfIds = [];
      this.searchQuery = "";

      if (sprintId === "All") {
        this.createData();
      } else {
        for (var i = 0; i < this.sprintList.length; i++) {
          if (this.sprintList[i].id === sprintId) {
            listOfIds = this.sprintList[i].tasks;
          }
        }

        var data = [];

        for (var i = 0; i < this.taskList.length; i++) {
          for (var j = 0; j < listOfIds.length; j++) {
            if (this.taskList[i].id === listOfIds[j]) {
              data.push(this.taskList[i]);
            }
          }
        }

        this.filderData(data);
      }
    },

    async getTaskFilter(filter) {
      var data = [];

      if (filter === "Search") {
        for (var i = 0; i < this.taskList.length; i++) {
          if (
            this.taskList[i].subject
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          ) {
            data.push(this.taskList[i]);
          }
        }
      } else {
        data = this.taskList;
      }

      this.filderData(data);
    },

    searchWord() {
      this.sprintSelected = "";
      if (this.searchQuery) {
        this.getTaskFilter("Search");
      } else {
        this.getTaskFilter("All");
      }
    },

    async filderData(data) {
      this.backlog = [];
      this.in_progress = [];
      this.rejected = [];
      this.done = [];

      for (var i = 0; i < data.length; i++) {
        if (data[i].status === "Backlog") {
          this.backlog.push(data[i]);
        }
        if (data[i].status === "Em execução") {
          this.in_progress.push(data[i]);
        }
        if (data[i].status === "Rejeitado") {
          this.rejected.push(data[i]);
        }
        if (data[i].status === "Finalizado") {
          this.done.push(data[i]);
        }
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1680) {
        this.isActive = false;
        this.limitCharactersValue = 20;
      } else {
        this.isActive = true;
        this.limitCharactersValue = 10;
      }
    },

    setTaskData(item) {
      this.taskData = item;
      this.getTaskFilesData(item.id);
    },

    async getTaskFilesData(id) {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
        const content = response.headers["content-type"];
        download(response.data, name, content);
        }
      });
    },

    gradientColorProgressBar() {
      return `background: linear-gradient(90deg, currentColor ${this.taskData.percentage}%, rgba(0,0,0,0.1) ${this.taskData.percentage}%); animationDelay: -${this.taskData.percentage}s; !important`;
    },

    gradientColorProgressBarTimeSheet() {
      return `background: linear-gradient(90deg, currentColor ${
        (Number(this.timeSheetDataHoursQuantity) / 12) * 100
      }%, rgba(0,0,0,0.1) ${
        (Number(this.timeSheetDataHoursQuantity) / 12) * 100
      }%); animationDelay: -${
        (Number(this.timeSheetDataHoursQuantity) / 12) * 100
      }s; !important`;
    },

    restrictions(value) {
      return AccessControl(value);
    },

    async getTaskList() {
      this.loading = true;
      await axios
        .get(`${URL_API}task/project-kanban/${this.projectId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.taskList = response.data;
        })
        .then(() => {
          this.createData();
          this.loading = false;
        });

      //Sprint select
      await axios({
        method: "get",
        url: `${URL_API}sprint/projectId/${this.projectId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.sprintList = response.data;
        for (var j = 0; j < response.data.length; j++) {
          this.sprintOptions.push({
            label: response.data[j].subject,
            value: response.data[j].id,
          });
        }
        this.sprintOptions.push({
          label: "Todos",
          value: "All",
        });
      });
    },

    async createData() {
      this.backlog = [];
      this.in_progress = [];
      this.rejected = [];
      this.done = [];

      for (var i = 0; i < this.taskList.length; i++) {
        if (this.taskList[i].status === "Backlog") {
          this.backlog.push(this.taskList[i]);
        }
        if (this.taskList[i].status === "Em execução") {
          this.in_progress.push(this.taskList[i]);
        }
        if (this.taskList[i].status === "Rejeitado") {
          this.rejected.push(this.taskList[i]);
        }
        if (this.taskList[i].status === "Finalizado") {
          this.done.push(this.taskList[i]);
        }
      }
    },

    badgeColor(value) {
      if (value === "Normal") {
        return "primary";
      }
      if (value === "Alta") {
        return "warning";
      }
      if (value === "Urgente") {
        return "danger";
      }
      return "primary";
    },

    taskPriorityLocale(value) {
      if (value === "Normal") {
        return this.getColLabel("normal");
      } else if (value === "Alta") {
        return this.getColLabel("high");
      } else {
        return this.getColLabel("urgent");
      }
    },

    getColLabel(colName) {
      return this.$i18n.t(colName);
    },

    seeChange(event, col) {
      if (event.added) {
        var taskId = event.added.element.id;
        var taskType = col;
        var taskObj = event.added.element;

        taskObj.status = taskType;
        var currentUserId = this.userData.id;

        if (taskObj.membersEmails.length > 0 && col != "Backlog") {
          //movimentação normal
        }

        if (col === "Finalizado") {
          taskObj.percentage = 100;
        }         

        if (taskObj.membersEmails.length > 0 && col === "Backlog") {
          //de fora pra backlog
          taskObj.members = [];
          taskObj.membersNames = [];
          taskObj.membersEmails = [];
        }

        if (taskObj.membersEmails.length === 0 && col === "Backlog") {
          //movimentação normal em backlog
        }

        if (taskObj.membersEmails.length === 0 && col != "Backlog") {
          //de backlog pra fora

          for (var i = 0; i < this.taskList.length; i++) {
            if (this.taskList[i].id === taskId) {
              this.taskList[i].membersEmails.push(this.userData.email);
              this.taskList[i].membersNames.push(
                this.currentEmployeerObj.data.fullName
              );
            }
          }

          taskObj.members = [currentUserId];
        }

        axios({
          method: "put",
          url: `${URL_API}taskStatus/${taskId}`,
          headers: getHeader(this.userData),
          data: taskObj,
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.change"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updatedB"),
            },
          });
        });
      }
    },

    lateTask(param) {
      var dateToCompare = moment(param.endDate);
      var today = moment(new Date());

      if (param.percentage > 99) {
        return false;
      }

      if (dateToCompare.startOf("day").isSameOrAfter(today.startOf("day"))) {
        return false;
      }
      return true;
    },

    minHeight() {
      var heightList = [];

      heightList.push(this.backlog.length);
      heightList.push(this.in_progress.length);
      heightList.push(this.rejected.length);
      heightList.push(this.done.length);

      var max_of_array = Math.max.apply(Math, heightList);

      var height = max_of_array * 275;
      return `min-height: ${height}px ; !important`;
    },

    avatarFullName(list) {
      var listReturn = [];

      if (list.membersNames.length != 0) {
        for (var i = 0; i < list.membersNames.length; i++) {
          var avatar = "";
          if (list.avatar[i] != null) {
            avatar = list.avatar[i];
          }
          listReturn.push({
            fullName: list.membersNames[i].split(" ").slice(0, 2).join(" "),
            avatar: avatarText(
              list.membersNames[i].split(" ").slice(0, 2).join(" ")
            ),
            avatarImg: avatar,
          });
        }
        return listReturn;
      }
      return listReturn;
    },

    taskPriority(priority) {
      if (priority == "NORMAL") {
        return "Normal";
      }
      if (priority == "HIGH") {
        return "Alta";
      }
      if (priority == "URGENT") {
        return "Urgente";
      }
      return "Normal";
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    submitNewTimeSheet() {
      if (
        this.timeSheetData.projectId === null ||
        this.timeSheetData.projectId === "" ||
        this.timeSheetData.projectId === undefined ||
        this.timeSheetData.taskId === null ||
        this.timeSheetData.taskId === "" ||
        this.timeSheetData.taskId === undefined ||        
        this.timeSheetData.date === null ||
        this.timeSheetData.date === undefined ||
        this.timeSheetData.date === ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("Error"),
            icon: "XIcon",
            variant: "danger",
            text: this.$i18n.t("dateNot"),
          },
        });
      } else {
        this.timeSheetData.date = date(
          this.$i18n.locale,
          this.timeSheetData.date
        );
        this.timeSheetData.userId = this.userData.id;

        this.timeSheetData.hoursQuantity = Number(
          this.timeSheetDataHoursQuantity
        );

        axios({
          method: "post",
          url: `${URL_API}timeSheet`,
          headers: getHeader(this.userData),
          data: this.timeSheetData,
        })
          .then(() => {
            this.updateTaskData(this.timeSheetData.hoursQuantity);
            this.timeSheetData = {};
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: `Lançamento de horas criado com sucesso`,
              },
            });
          })
          .catch((error) => {
            this.disabledButton = false;
            console.log(error);
          });
      }
    },

    updateTaskData(quantity) {
      var finalValue = 0;

      if (this.currentEmployeerObj.data.fixedCost != null) {
        var hourCost =
          Number(this.currentEmployeerObj.data.fixedCost) /
          this.paramData.hoursMonth;
        finalValue = hourCost * quantity;
      }

      axios({
        method: "put",
        url: `${URL_API}taskCost/${this.timeSheetData.taskId}/${quantity}/${finalValue}`,
        headers: getHeader(this.userData),
      });
    },
  },

  setup() {
    return {
      // Avatar
      avatarText,
    };
  },
};
</script>

<style scoped>
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 0.5px solid #51565a;
}

.drag-card {
  opacity: 1 !important;
}

.progress-bar {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.list-group .list-group-flush {
  background: #ebebeb;
  padding: 3px !important;
  border: 2px solid #f5f5f5;
  border-radius: 10px 10px 10px 10px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  padding: 10px !important;
  border: 2px solid #f5f5f5;
  background: #ebebeb;
  z-index: 1;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #313a46;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes color-range {
  0% {
    color: #f57070;
  }
  50% {
    color: #f5f570;
  }
  100% {
    color: #70f570;
  }
}

.slider-timeSheet {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range-timeSheet 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider-timeSheet:hover {
  opacity: 1;
}

.slider-timeSheet::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 37px;
  height: 25px;
  background: #313a46;
  cursor: pointer;
}

.slider-timeSheet::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes color-range-timeSheet {
  0% {
    color: #ada3ff;
  }
  50% {
    color: #8170ff;
  }
  100% {
    color: #604bff;
  }
}
</style>
